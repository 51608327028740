import { FunctionComponent } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import { TDK as defaultTDK } from "@/config/config";

const PageHead: FunctionComponent<{
  tdk?: {
    title?: string;
    description?: string;
    keywords?: string;
    cover?: string;
  };
}> = ({ tdk = defaultTDK }) => {
  const router = useRouter();
  tdk = {
    ...defaultTDK,
    ...tdk,
  };

  return (
    <Head>
      <title>{tdk.title}</title>
      <meta name="description" content={tdk.description}></meta>
      <meta name="keywords" content={tdk.keywords}></meta>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="KaratDAO" />
      <meta property="og:title" content={tdk.title} />
      <meta
        property="og:site_icon"
        content={"https://karatdao.com/logo192.png"}
      />
      <meta property="og:description" content={tdk.description} />
      <meta
        property="og:url"
        content={`https://karatdao.com${router.asPath}`}
      />
      <meta property="og:image" content={tdk.cover} />
      <meta name="twitter:title" content={tdk.title} />
      <meta name="twitter:description" content={tdk.description} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image" content={tdk.cover}></meta>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@type": "Product",
        "image": "https://karatdao.com/logo512.png",
        "url": "https://karatdao.com",
        "name": "KaratDAO"
        }`,
        }}
      ></script>
    </Head>
  );
};

export default PageHead;
